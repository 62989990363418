import moment from "moment";
import { CellAddressType } from "../models/enums";
import { Instance } from "mobx-state-tree";
import { CellAddress, CellAddressActivities } from "../models/Brick/CellAddress";
import { Filters } from "../models/Common";
import * as Sentry from "@sentry/browser";
import config from "../configs/clientConfig";
import { AxiosHeaders } from '../models/admin/definitions/enums'

// helper constants
const __hardcoded = {
    "1_planned": "notStarted",
    "3_active": "OnGoing",
    "8_done": "completed"
};
const PARTITION = 8;
const MAP = {
    0: "01",
    1: "02",
    2: "04",
    3: "08",
    4: "10",
    5: "20",
    6: "40",
    7: "80",
    NONE: "00",
    ALL: "FF"
};
const maskMap = Array.from(Object.keys(MAP), (k) => parseInt('0x' + MAP[k], 16));

/*
  Pluck only given indices
  ,----
  |   For an array,
  |   efficiently gives you back the elements
  |   at the provided indices.
  `----
*/

export function pluck<T>(this: T[], ...args: number[]): T[] {
    let returnArr: T[] = [], i = 0;
    const argsLen = args.length;
    if (argsLen === 0) { return this; }
    // if (intent) {
    for (; i < argsLen; i++) { returnArr.push(this[args[i]]); }
    // }
    // else {
    //     const len = this.length;
    //     returnArr = Array.from(this);
    //     for (; i < argsLen; i++) { returnArr[argsLen[i]] = returnArr[len - i - 1]; returnArr.pop(); }
    // }
    return returnArr;
}



/*
  Binary to Index
  ,----
  | For an ArrayBuffer,
  | efficiently gives you back the indices that should be filtered.
  `----
*/

export function giveIndices(this: ArrayBuffer): number[] {
    const arr: Uint8Array = new Uint8Array(this);
    if (arr.every(el => el === 0)) { return []; }
    if (arr.every(el => el === 255)) { return []; }
    let loop: number = 0;
    const view = Array.from(arr, (val: number, index: number) => {
        if (val === 0) { return []; }
        else if (0 === (val ^ maskMap[maskMap.length - 1])) {
            const lBound: number = index * PARTITION;
            return Array.from(Array(PARTITION).keys(), el => el + lBound);
        }
        else {
            let res: number[] = [];
            const lBound: number = index * PARTITION;
            for (loop = 0; loop < PARTITION; loop++) {
                if (val & maskMap[loop]) { res.push(lBound + loop); }
            }
            return res;
        }
    });
    return view.flat();
}

export function indicesToEncoded(values: number[], len: number): string {
    return encodeURIComponent(String.fromCharCode.apply(null, values.reduce((acc: Uint8Array, index: number) => {
        const [i, val] = [Math.floor(index / PARTITION), index % PARTITION];
        acc[i] = acc[i] | parseInt("0x" + MAP[val], 16);
        return acc;
    }, new Uint8Array(Math.ceil(len / PARTITION)).fill(0))
    ));
}

/**
 * Function that processes the column header for the excel data.
 **/
export function processExcelData(columns: any, data: any) {
    let res = [] as any;
    data.forEach(el => {
        let row = {};
        columns.forEach(col => {
            // currently only isExcelField prop is checked. This means if any
            // column is set to invisible dynamically in the future (there's no
            // table currently where that's the case), you have to add the
            // condition here too.
            if (col.isExcelField) {
                const key = col.excelExportTitle || col.name;
                row[key] = col.excelExport(el);
            }
        });
        res.push({ ...row });
    });
    return res;
}

export function isDelayed(statusString: string, planFrom?: string, planTo?: string, _?: string, actualTo?: string): boolean {
    const today = moment().startOf('day');
    const status = __hardcoded[statusString] || "plainWrong";
    switch (status) {
        case 'started': return !!planTo && today.diff(moment(planTo), 'days') > 0;
        case 'notStarted': return !!planFrom && today.diff(moment(planFrom), 'days') > 0;
        case 'completed': return !!planTo && !!actualTo && moment(actualTo).diff(moment(planTo), 'days') > 0;
        default: return false;
    }
}

export function isDelayedEnd(planTo?: string, actualTo?: string): boolean {
    if (actualTo)
        return !!planTo && !!actualTo && moment(actualTo).diff(moment(planTo), 'days') > 0;
    else
        return !!planTo && moment().startOf('day').diff(moment(planTo), 'days') > 0;
}

export function isDelayedStart(planFrom?: string, actualFrom?: string): boolean {
    if (actualFrom)
        return !!planFrom && !!actualFrom && moment(actualFrom).diff(moment(planFrom), 'days') > 0;
    else
        return !!planFrom && moment().startOf('day').diff(moment(planFrom), 'days') > 0;
}

export function getStatusName(id: string): string {
    return __hardcoded[id] || "Incorrect Value";
}

export function extendStyles<T>(obj: object, newObj: object) {
    return { ...obj, ...newObj };
}
// TODO: @Aniket Mule: Kindly change OnGoing back to started, or convert every
// instance of started to OnGoing.
export function calcStatus(completed: number, inProgress: number, total: number): "completed" | "OnGoing" | "notStarted" | "plainWrong" {
    if (total < completed + inProgress) { return "plainWrong"; }
    else if (total === completed) { return "completed"; }
    else if (inProgress || completed) { return "OnGoing"; }
    else if (inProgress === 0 && completed === 0) { return "notStarted"; }
    return "plainWrong"
}

export function getReportName(name: string) {
    switch (name) {
        case "barChart": return "Bar Chart";
        case "table": return "By Units";
        case "brickGraph": return "By Floors";
        case "baratheon": return "By Floors";
        default: return "None"
    }
}

export const sortingStrategyRev = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return b.id - a.id;
    return (b.name as string).localeCompare(a.name as string);
};

export const sortingStrategy = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return a.id - b.id;
    return (a.name as string).localeCompare(b.name as string);
};

export const sortingStrategyName = (a, b) => {
    return (a.name as string).localeCompare(b.name as string);
};

export const sortingWithPrecedence = (a, b) => {
    if (a.precedence === undefined && b.precedence === undefined) {
        return sortingStrategyName(a, b);
    }
    if (a.precedence === null && b.precedence !== null) {
        return 1;
    }
    if (a.precedence !== null && b.precedence === null) {
        return -1;
    }
    return ((a.precedence || 0) - (b.precedence || 0) === 0) ? sortingStrategyName(a, b) : a.precedence! - b.precedence!;
}

function assignValue(object, key, value) {
    object[key] = value;
}

export const groupKeyBy = (input, key) => {
    return input?.reduce((result, currentValue) => {
        let groupKey = currentValue[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
    }, {});
};

/** Used to check objects for own properties. */
const hasOwnProperty = Object.prototype.hasOwnProperty

export const groupBy = (input, cb) => {
    return input?.reduce((result, currentValue, key) => {
        key = cb(currentValue)
        if (hasOwnProperty.call(result, key)) {
            result[key].push(currentValue)
        } else {
            assignValue(result, key, [currentValue])
        }
        return result
    }, {})
}

export interface IActivityColumnsArgs {
    columnType: CellAddressType;
    columns: Instance<typeof CellAddress>[];
    columnSorted: Instance<typeof CellAddress>[];
    filterRef: Instance<typeof Filters>;
};

export function getColumnsFiltered({ columnType, columns, columnSorted, filterRef }: IActivityColumnsArgs): Instance<typeof CellAddress>[] {
    if (columnType !== CellAddressType.ACT_STRING) { return columnSorted; }
    const arrayBuffer = new ArrayBuffer(Math.ceil(columns.length / 8));
    const len = arrayBuffer.byteLength;
    const decoded = filterRef.activity && decodeURIComponent(filterRef.activity);
    if (!decoded) { return columnSorted; }
    let view = new Uint8Array(arrayBuffer), loop = 0;
    for (loop = 0; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
    const indices = giveIndices.apply(arrayBuffer);
    return indices.length === 0 ? columnSorted : pluck.apply(columnSorted, indices);
};

export function getIndicesCol({ columnType, columns, columnSorted, filterRef }: IActivityColumnsArgs): number[] {
    if (!filterRef.activity) { return []; }
    if (columnType !== CellAddressType.ACT_STRING) { return []; }
    const arrayBuffer = new ArrayBuffer(Math.ceil(columns.length / 8));
    const len = arrayBuffer.byteLength;
    const decoded = filterRef.activity && decodeURIComponent(filterRef.activity);
    if (!decoded) { return []; }
    let view = new Uint8Array(arrayBuffer), loop = 0;
    for (; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
    return giveIndices.apply(arrayBuffer);
};

export function getStages({ columnSorted }: { columnSorted: Instance<typeof CellAddressActivities>[]; }): { stage: string; length: number; index: number; order: number }[] {
    const interArray: {
        stage: string;
        index: number;
        order: number;
    }[] = columnSorted.reduce(
        (
            acc: { stage: string; index: number; order: number }[],
            column: Instance<typeof CellAddressActivities>,
            ind: number
        ) =>
            column.stage &&
                column.stage !==
                (columnSorted[ind > 0 ? ind - 1 : 0] &&
                    columnSorted[ind > 0 ? ind - 1 : 0].stage)
                ? [
                    ...acc,
                    {
                        stage: columnSorted[ind > 0 ? ind - 1 : 0].stage,
                        index: ind - 1,
                        order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
                    }
                ]
                : ind + 1 === columnSorted.length
                    ? [
                        ...acc,
                        {
                            stage: column.stage,
                            index: ind + 1,
                            order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
                        }
                    ]
                    : acc,
        []
    );
    return interArray.reduce(

        (acc, { stage, index, order }, ind) => [
            ...acc,
            {
                stage,
                order,
                index: ind === 0 ? 0 : interArray[ind - 1].index,
                length: ind === 0 ? index : index - interArray[ind - 1].index
            }
        ],
        []
    );
}

export function getPdf(url: string, fileName: string) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
}

export function groupByKeys(keys: string[], input: { [K: string]: any; }[]) {
    let internalMap: { [K: string]: any[]; } = {}, i = 0, len = input.length, res: Array<Array<any>> = [];
    for (i; i < len; i++) {
        const record = input[i];
        const internalKey = keys.map(k => record[k]).join('_');
        if (!internalMap[internalKey]) { internalMap[internalKey] = [keys.reduce((acc, k) => ({ ...acc, [k]: record[k] }), {})]; }
        internalMap[internalKey].push(record);
    }
    Object.keys(internalMap).forEach(k => {
        res.push([internalMap[k][0], internalMap[k]?.slice(1)]);
    })
    return res;
}

export function getFileNameFromRow(original: { [K: string]: any; }): string {
    const fileName = original['block_name'] + "-" + original['unit_name'] + "-" + original['activity_type_name'] + ".pdf";
    return fileName.replaceAll("/", "-");
}

export function transformDate(val: string): string { return moment(val).startOf('day').toISOString(true); }
export function transformTime(val: string): string { return `${moment().format('YYYY-MM-DD')}T${val}:00.000+05:30`; }
export function transformDateTime(val: string): string { return moment(val).toISOString(true); }
export function transformPercent(val: string): string { return val ? `${val.replace(/[^0-9]+/g, "")} %` : ""; }
export function transformPhoto(val: string[]) { return val.length ? val : ""; }
export function transformNumeric(val: number): string { return val?.toString() || ""; }
// Expect to put some transform on photo
export function copyFileUrl(fileId: string, version?: string) { return version === undefined ? `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}&versionId=${version}` : `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}`; };


export const formEditReducer = (state: { [K: string]: any; }, action: { type: string; payload: any; }) => {
    switch (action.type) {
        case 'ADD_IMAGE': return { ...state, isChanged: true, [action.payload.field]: [...state[action.payload.field], action.payload.input] };
        case 'REMOVE_IMAGE': return { ...state, isChanged: true, [action.payload.field]: state[action.payload.field].filter(file => file != action.payload.input) };
        case 'REPLACE_GENERIC':
        case 'REPLACE_DATE_TIME': return state[action.payload.field] === action.payload.input ? state : { ...state, isChanged: true, [action.payload.field]: action.payload.input };
        case 'RESET': return { ...action.payload, isChanged: false };
        case 'SET_CHANGE_STATE': return { ...state, isChanged: !!action.payload };
        case 'SET_UPLOAD': return { ...state, isChanged: true, [action.payload.field]: action.payload.input };
        case 'EDIT_UPLOAD': return { ...state, isChanged: true, [action.payload.field]: state[action.payload.field].map(f => f.fileId === action.payload.input.fileId ? action.payload.input : f) };
        case 'DELETE_UPLOAD': return { ...state, isChanged: true, [action.payload.field]: state[action.payload.field].filter(f => f.fileId !== action.payload.input.fileId) };
        default: return state;
    }
}

interface IInterleave<T extends { [K: string]: any; }, S extends { [K: string]: any; }> {
    array1: T[];
    array2: S[];
    key1: string;
    key2: string;
    comparator(val1: any, val2: any): boolean;
}

export function interleave<T extends { [K: string]: any; }, S extends { [K: string]: any; }>({ array1, array2, key1, key2, comparator }: IInterleave<T, S>): { array: number; index: number; }[] {
    let res: { array: number; index: number; }[] = [], i = 0, j = 0;
    const maxLen = Math.max(array1.length, array2.length);
    for (; i < maxLen; i++) {
        const el1 = array1[i], el2 = array2[i];
        if (el1) {
            const elt = { array: 0, index: i };
            for (j = res.length - 1; j >= 0; j--) {
                const val = res[j].array ? array2[res[j].index][key2] : array1[res[j].index][key1];
                if (comparator(el1[key1], val)) { break; }
            }
            if (j >= 0) { res.splice(j + 1, 0, elt); }
            else { res.unshift(elt); }
        }
        if (el2) {
            const elt = { array: 1, index: i };
            for (j = res.length - 1; j >= 0; j--) {
                const val = res[j].array ? array2[res[j].index][key2] : array1[res[j].index][key1];
                if (comparator(el2[key2], val)) { break; }
            }
            if (j >= 0) { res.splice(j + 1, 0, elt); }
            else { res.unshift(elt); }
        }
    }
    return res;
}

export function sortDateFunc(a: any, b: any) {
    return moment.utc(b.createdAt).diff(moment.utc(a.createdAt), 'millisecond')
}

export function getInitials(fullName) {
    const nameArray = fullName.split(' ');
    if (nameArray.length >= 2) {
        const firstNameInitial = nameArray[0][0];
        const lastNameInitial = nameArray[nameArray.length - 1][0];
        return `${firstNameInitial}${lastNameInitial}`;
    }
    return nameArray[0][0];
}

export function getRandomColor() {
    // Generate a random color in hexadecimal format (#RRGGBB)
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export const extractOptionExpressionName = (optionsExpression: string | undefined): string => {
    const variableString = optionsExpression && optionsExpression.match(/\$(.*)/) || "" //$customerList
    return variableString && variableString[1];
}

export const convertToISO = (inputDate: any) => moment(inputDate).add(23, 'h').add(59, 'm').add(59, 's').toISOString();

export const stringToColour = (str: string) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let rgb: { [K: string | number]: any; } = ['r', 'g', 'b'], h = 0, s = 0, l = 0;
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        // colourHex += value.toString(16).padStart(2, '0');
        rgb[rgb[i]] = value / 255;
    }
    const { r, g, b } = rgb;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    if (delta === 0) {
        l = cmax;
    } else {
        // Calculate lightness
        l = (cmax + cmin) / 2;
        // Calculate saturation
        s = delta / (1 - Math.abs(2 * l - 1));
        // Red is max
        if (cmax == r) {
            h = ((g - b) / delta) % 6;
        }
        // Green is max
        else if (cmax == g) {
            h = (b - r) / delta + 2;
        }
        // Blue is max
        else {
            h = (r - g) / delta + 4;
        }
    }
    h = (Math.round(h * 60) + 360) % 360;
    // scale saturation between 20 and 90
    s = +(s * 70).toFixed(1) + 20;
    // scale lightness between 25 and 50
    l = +(l * 25).toFixed(1) + 25;
    return { hsl: { h, s, l } };
};

export const prepareHSLString = ({ h, s, l }: { h: number; s: number; l: number; }) => `hsl(${h} ${s}% ${l}%)`;

export const adjustColour = ({ mode, amount, ...rest }: { h: number; s: number; l: number; mode: 'h' | 's' | 'l'; amount: number }) => ({
    ...rest,
    [mode]: mode === 'h' ? (rest[mode] + amount + 360) % 360 : Math.min(100, rest[mode] + amount)
});


export const getType = (fileType: string) => /([^\/]+$)/.exec(fileType || "applications/file")!![1]
export const getFileExtension = (fileName: string) => {
    const match = fileName.match(/\.([a-zA-Z0-9]+)$/);
    return match ? match[1] : null; // Returns the extension or null if not found
};
export const isImageType = (fileType: string) => (getType(fileType) == "jpeg" || getType(fileType) == "jpg" || getType(fileType) == "png")

export const captureEventCallback = (data: { [K: string]: any; }) => (scope: Sentry.Scope) => {
    scope.setLevel('warning');
    scope.setExtras({ ...data });
    Sentry.captureMessage("No Data Found");
};

export function comparePrimitiveArrays<T extends any>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) { return true; }
    let i = 0, len = arr1.length;
    for (; i < len; i++) {
        if (!arr2.includes(arr1[i])) { return true; }
    }
    return false;
}

export const getAxiosHeader = (input: string) => {
    switch (input) {
        case AxiosHeaders.JSON_BODY: return { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        case AxiosHeaders.FORM_DATA: return { 'Content-Type': 'multipart/form-data' }
        case AxiosHeaders.QUERY: return {}
        default: return {}
    }
}
export const sortingStrategyPosition = (positionKey: string, fallbackKey: string = "name") => (a, b) => {
    if (typeof a[positionKey] === "number" && typeof b[positionKey] === 'number') {
        const n = a[positionKey] - b[positionKey];
        if (!n) {
            return (a[fallbackKey] as string).localeCompare(b[fallbackKey] as string);
        }
        return n;
    }
    return (a[fallbackKey] as string).localeCompare(b[fallbackKey] as string);
};


export const getImageAcceptType = (allowedTypes: string[]) => {
    const imageTypes = ['jpg', 'jpeg', 'png', 'svg'];
    // const docTypes = ['pdf','docx','doc','xlsx','ppt','csv','txt'];

    if (!allowedTypes) return ''

    if (allowedTypes.length === 1)
        return `.${allowedTypes[0]}`
    else if (imageTypes.includes(allowedTypes[0]))
        return "image/png,image/jpeg,image/jpg"
    else
        return ''
}

export const millisToMinutesAndSeconds = (millis: number) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000) //.toFixed(0);
    return minutes + 'm ' + ":" + (seconds < 10 ? '0' : '') + seconds + 's';
}

export const replaceURLPlaceholders = (url: string, formValues: any) => {
    // Regular expression to match placeholders of the pattern :<string>
    const placeholderRegex = /:(\w+)/g;

    // Replace each placeholder with the corresponding value from formValues
    return url.replace(placeholderRegex, (_, key) => formValues[key] || '');
};


export const replaceURLPlaceholders2 = (url: string, values: { [key: string]: any }) => {
    return url.replace(/:\w+/g, match => {
        const key = match.slice(1);
        return values[key] || match;
    });
};

export function appendFileVersion(file: { fileId: string; fileName: string; fileStatus: string; fileType: string; version: number; createdDate?: string; original_file_name: string, file_name: string }, version?: number) {
    // Split the file name by the last dot to separate the name and extension

    const lastDotIndex = version == undefined ? file.fileName.lastIndexOf('.') : file.file_name?.lastIndexOf('.');

    // Extract the file name without the extension
    const fileName = version == undefined ? file.fileName.substring(0, lastDotIndex) : file.file_name?.substring(0, lastDotIndex);

    // Extract the extension
    const extension = version == undefined ? file.fileName.substring(lastDotIndex + 1) : file.file_name?.substring(lastDotIndex + 1);

    return fileName + '_v' + file.version;
}

/**Test code for prestige tender summary report */
const tenderData = [
    {
        "id": "3247501",
        "name": "prepare start ticket payload",
        "priority": "L1",
        "priorityTitle": "Critical",
        "processVariables": {
            "type": "prestigeTender",
            "title": "prepare start ticket payload",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L1",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-16",
            "description": "",
            "packagetype": "stp",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 60,
            "approvedUploadReminderDate": "2024-06-17T00:00:00Z",
            "form_prestigeTenderTargetDateForm_outcome": ""
        },
        "createdAt": "2024-08-23T00:09:44.865+05:30",
        "lastUpdated": "2024-08-23T00:10:42.101+05:30",
        "taskId": [
            "3247565"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "adminTickets",
            "past7days"
        ],
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3245001",
        "name": "prepare start ticket payload",
        "priority": "L1",
        "priorityTitle": "Critical",
        "processVariables": {
            "type": "prestigeTender",
            "title": "prepare start ticket payload",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L1",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-16",
            "description": "",
            "packagetype": "stp",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 60,
            "approvedUploadReminderDate": "2024-06-17T00:00:00Z",
            "form_prestigeTenderTargetDateForm_outcome": ""
        },
        "createdAt": "2024-08-23T00:02:03.901+05:30",
        "lastUpdated": "2024-08-23T00:02:45.632+05:30",
        "taskId": [
            "3245071"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "adminTickets",
            "past7days"
        ],
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3193292",
        "name": "CAN THIS STUPID SOFTWARE WORK ONCE WITHOUT HAVING TO CREATE 8000000000 TEST TICKETS IN MY LIFE???????",
        "priority": "L4",
        "priorityTitle": "Normal",
        "processVariables": {
            "type": "prestigeTender",
            "title": "CAN THIS STUPID SOFTWARE WORK ONCE WITHOUT HAVING TO CREATE 8000000000 TEST TICKETS IN MY LIFE???????",
            "tenant": "myhome",
            "dueDate": "",
            "boqRecvd": "2024-08-16T19:53:24.920943Z",
            "category": "",
            "location": [],
            "priority": "L4",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-31",
            "description": "",
            "packagetype": "owc",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderInProcess",
            "packagesDuration": 30,
            "tenderdetailsone": "[3193362]",
            "approvedUploadReminderDate": "2024-08-01T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": "",
            "form_prestigeTenderUploadReviewForm_outcome": "accept"
        },
        "createdAt": "2024-08-17T01:22:12.519+05:30",
        "lastUpdated": "2024-08-17T01:23:45.459+05:30",
        "type": "prestigeTender",
        "status": "prestigeTenderComplete",
        "statusTitle": "Completed",
        "statusCategory": "close",
        "requestTypeTitle": "Prestige Tender Tracker",
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "past7days"
        ]
    },
    {
        "id": "3193155",
        "name": "Fifteenth time's the charm",
        "priority": "L1",
        "priorityTitle": "Critical",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Fifteenth time's the charm",
            "tenant": "myhome",
            "dueDate": "",
            "boqRecvd": "2024-08-16T19:46:56.551344Z",
            "category": "",
            "location": [],
            "priority": "L1",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-31",
            "closeduDate": "2024-08-16T19:45:32.023156Z",
            "description": "",
            "packagetype": "plumbing",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderInProcess",
            "packagesDuration": 45,
            "tenderdetailsone": "[3193233]",
            "approvedUploadReminderDate": "2024-07-17T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": "",
            "form_prestigeTenderUploadReviewForm_outcome": "accept"
        },
        "createdAt": "2024-08-17T01:15:31.655+05:30",
        "lastUpdated": "2024-08-17T01:17:10.462+05:30",
        "type": "prestigeTender",
        "status": "prestigeTenderComplete",
        "statusTitle": "Completed",
        "statusCategory": "close",
        "requestTypeTitle": "Prestige Tender Tracker",
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "past7days"
        ]
    },
    {
        "id": "3192772",
        "name": "OO",
        "priority": "L5",
        "priorityTitle": "Low",
        "processVariables": {
            "type": "prestigeTender",
            "title": "OO",
            "tenant": "myhome",
            "dueDate": "",
            "boqRecvd": "2024-08-16T14:44:57.468099Z",
            "category": "",
            "location": [],
            "priority": "L5",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-30",
            "description": "",
            "packagetype": "gfrc",
            "closedDateoo": "2024-08-16T14:42:23.994115Z",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderInProcess",
            "packagesDuration": 30,
            "tenderdetailsone": "[3192846]",
            "approvedUploadReminderDate": "2024-07-31T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": "",
            "form_prestigeTenderUploadReviewForm_outcome": "accept"
        },
        "createdAt": "2024-08-16T20:11:53.083+05:30",
        "lastUpdated": "2024-08-16T20:15:14.439+05:30",
        "type": "prestigeTender",
        "status": "prestigeTenderComplete",
        "statusTitle": "Completed",
        "statusCategory": "close",
        "requestTypeTitle": "Prestige Tender Tracker",
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "past7days"
        ]
    },
    {
        "id": "3192510",
        "name": "Demo to Folks",
        "priority": "L2",
        "priorityTitle": "High",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Demo to Folks",
            "tenant": "myhome",
            "dueDate": "",
            "boqRecvd": "2024-08-16T11:02:46.863688Z",
            "category": "",
            "location": [],
            "priority": "L2",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-10-31",
            "description": "",
            "packagetype": "joinery",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderInProcess",
            "packagesDuration": 45,
            "tenderdetailsone": "[3192579]",
            "approvedUploadReminderDate": "2024-09-16T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": "",
            "form_prestigeTenderUploadReviewForm_outcome": "accept"
        },
        "createdAt": "2024-08-16T16:06:46.63+05:30",
        "lastUpdated": "2024-08-16T16:36:27.139+05:30",
        "type": "prestigeTender",
        "status": "prestigeTenderComplete",
        "statusTitle": "Completed",
        "statusCategory": "close",
        "requestTypeTitle": "Prestige Tender Tracker",
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "past7days",
            "priHigh"
        ]
    },
    {
        "id": "3180124",
        "name": "Test #3",
        "priority": "L2",
        "priorityTitle": "High",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Test #3",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L2",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-08-30",
            "description": "",
            "packagetype": "carparkMarking",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 30,
            "approvedUploadReminderDate": "2024-07-31T00:00:00Z",
            "form_prestigeTenderTargetDateForm_outcome": ""
        },
        "createdAt": "2024-08-12T18:45:41.517+05:30",
        "lastUpdated": "2024-08-14T17:28:11.019+05:30",
        "taskId": [
            "3185109"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "adminTickets",
            "priHigh"
        ],
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3180083",
        "name": "Test #2",
        "priority": "L4",
        "priorityTitle": "Normal",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Test #2",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L4",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-11-21",
            "description": "",
            "packagetype": "wtp",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 45,
            "tenderdetailsone": "[3185072]",
            "approvedUploadReminderDate": "2024-10-07T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": "",
            "form_prestigeTenderUploadReviewForm_outcome": "reject"
        },
        "createdAt": "2024-08-12T18:21:36.408+05:30",
        "lastUpdated": "2024-08-14T21:22:39.26+05:30",
        "taskId": [
            "3190081"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "watchers": [
            "FBT_USR_SK"
        ],
        "filteredViews": [
            "adminTickets"
        ],
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3180001",
        "name": "Boi",
        "priority": "L5",
        "priorityTitle": "Low",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Boi",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L5",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "targetDate": "2024-09-30",
            "description": "",
            "outcomeMeta": {
                "accept": {
                    "style": "",
                    "btn-label": "Accept",
                    "mandatory_comment": false
                },
                "reject": {
                    "style": "",
                    "btn-label": "Reject",
                    "mandatory_comment": true
                }
            },
            "packagetype": "extDev",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 60,
            "tenderdetailsone": "[3190118]",
            "approvedUploadReminderDate": "2024-08-01T00:00:00Z",
            "form_prestigeTenderUploadForm_outcome": "",
            "form_prestigeTenderTargetDateForm_outcome": ""
        },
        "createdAt": "2024-08-12T18:08:58.399+05:30",
        "lastUpdated": "2024-08-14T21:23:34.832+05:30",
        "taskId": [
            "3190130"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_SK",
                "name": "Sashi Kiran",
                "email": "sashi.kiran@falconbrick.com",
                "title": "Sales Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "adminTickets"
        ],
        "currentAssigneeList": [
            "FBT_USR_SK"
        ]
    },
    {
        "id": "3160001",
        "name": "Oh god",
        "priority": "L2",
        "priorityTitle": "High",
        "processVariables": {
            "type": "prestigeTender",
            "title": "Oh god",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L2",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "mang2",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "description": "",
            "packagetype": "civil",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited"
        },
        "createdAt": "2024-08-09T17:55:20.624+05:30",
        "lastUpdated": "2024-08-09T17:55:24.875776+05:30",
        "taskId": [
            "3160038"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "filteredViews": [
            "adminTickets",
            "priHigh"
        ],
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3237600",
        "name": "A new tender in Sayuk",
        "priority": "L2",
        "priorityTitle": "High",
        "processVariables": {
            "type": "prestigeTender",
            "title": "A new tender in Sayuk",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L2",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "sayuk",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "description": "",
            "packagetype": "swimmingPool",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 30
        },
        "createdAt": "2024-08-23T13:06:34.095+05:30",
        "lastUpdated": "2024-08-23T13:06:34.189797+05:30",
        "taskId": [
            "3237638"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    },
    {
        "id": "3237547",
        "name": "test prestige tender in sayuk",
        "priority": "L2",
        "priorityTitle": "High",
        "processVariables": {
            "type": "prestigeTender",
            "title": "test prestige tender in sayuk",
            "tenant": "myhome",
            "dueDate": "",
            "category": "",
            "location": [],
            "priority": "L2",
            "usersMap": {
                "FBT_USR_GM": "Gautam Mayur",
                "FBT_USR_SK": "Sashi Kiran"
            },
            "watchers": [],
            "initiator": "FBT_USR_SK",
            "projectId": "sayuk",
            "startDate": "",
            "startUser": "FBT_USR_SK",
            "plannerGrp": [
                "FBT_USR_GM"
            ],
            "propertyId": "",
            "description": "",
            "packagetype": "civil",
            "contractsGrp": [
                "FBT_USR_SK"
            ],
            "processStatus": "prestigeTenderDetailsAwaited",
            "packagesDuration": 60
        },
        "createdAt": "2024-08-23T09:28:03.187+05:30",
        "lastUpdated": "2024-08-23T09:28:03.728766+05:30",
        "taskId": [
            "3237585"
        ],
        "type": "prestigeTender",
        "status": "prestigeTenderDetailsAwaited",
        "statusTitle": "Tender Details Awaited",
        "statusCategory": "wip",
        "requestTypeTitle": "Prestige Tender Tracker",
        "assignee": [
            {
                "id": "FBT_USR_GM",
                "name": "Gautam Mayur",
                "email": "gautam.mayur@falconbrick.com",
                "title": "Customer Success Manager"
            }
        ],
        "createdBy": {
            "id": "FBT_USR_SK",
            "name": "Sashi Kiran",
            "email": "sashi.kiran@falconbrick.com",
            "title": "Sales Manager"
        },
        "currentAssigneeList": [
            "FBT_USR_GM"
        ]
    }
]

export const packages = [{ "id": "civil", "name": "Civil" }, { "id": "plumbing", "name": "Plumbing" }, { "id": "stp", "name": "STP" }, { "id": "joinery", "name": "Joinery UPVC/Al." }, { "id": "glazingPainting", "name": "St. Glazing Painting" }, { "id": "extDev", "name": "Ext Dev/ Hardscape" }, { "id": "railing", "name": "Railing (MS/SS)" }, { "id": "gfrc", "name": "GFRC" }, { "id": "wtp", "name": "WTP" }, { "id": "swimmingPool", "name": "Swimming Pool" }, { "id": "owc", "name": "OWC" }, { "id": "signages", "name": "Signages" }, { "id": "carparkMarking", "name": "Carpark marking" }]
export const packageDuration = { "owc": 30, "stp": 30, "wtp": 45, "gfrc": 30, "civil": 60, "extDev": 60, "joinery": 45, "railing": 45, "plumbing": 45, "signages": 45, "swimmingPool": 30, "carparkMarking": 30, "glazingPainting": 45 }
export const projectsMetadata = { "mang2": { "cl": "I don't know what that is but let's say it's a string", "vp": "Mr. Hoity Toity", "ser": "I don't know what that is but let's say it's a string", "struc": "I don't know what that is but let's say it's a string", "budget": "3 L", "location": "your face only", "startDate": "1/Jan/2024", "FinishDate": "30/Dec/2025", "SalableArea": "400000 sq ft", "devCategory": "H", "TotalBuiltupArea": "450000 sq ft" }, "sayuk": { "cl": "I don't know what that is but let's say it's a string", "vp": "Mr. Hoity Toitwo", "ser": "I don't know what that is but let's say it's a string", "struc": "I don't know what that is but let's say it's a string", "budget": "5 L", "location": "my face only", "StartDate": "", "FinishDate": "", "SalableArea": "", "devCategory": "C", "TotalBuiltupArea": "" } }

export const restructuredTenderData = (data: any = tenderData, packages: any, packageDuration: any, projectsMetadata: any) => {
    const groupedData = {};

    data.forEach(tenderTicket => {
        const processVars = tenderTicket.processVariables;
        const projectId = processVars.projectId || "";

        if (!groupedData[projectId]) {
            groupedData[projectId] = {
                projectName: projectId,
                location: projectsMetadata[projectId].location || "",
                devCategory: projectsMetadata[projectId].devCategory || "",
                vp: projectsMetadata[projectId].vp || "",
                contractLead: projectsMetadata[projectId].cl || "",
                arch: "",
                struc: projectsMetadata[projectId].struc || "",
                ser: projectsMetadata[projectId].ser || "",
                packages: [],
                packagesMap: {}
            };
        }

        packages.sort((a, b) => a.id.localeCompare(b.id)).find(pkg => {
            if (processVars.packagetype === pkg.id) { // If current ticket's packagetype is same as current package
                groupedData[projectId]['packagesMap'] = { // add the data for package.. if duplicate package exists, new one will always overwite
                    ...groupedData[projectId]['packagesMap'], [pkg.id]: {
                        packageType: pkg.id,
                        packageTypeName: pkg.name,
                        duration: packageDuration[pkg.id] / 30,
                        targetDate: processVars.targetDate || "",
                        boqReceivedDate: processVars.boqRecvd || "",
                        closedDate: processVars.closedDate || "",
                        contractorName: processVars.contractorname || "", // Assuming contractor name would come from FBT_USR_SK
                        status: tenderTicket.statusTitle || "",
                        subStatus: processVars.subStatus || "",
                        boqUploadTargetDate: processVars.approvedUploadReminderDate || "",
                    }
                }
            }
        })
    });

    // Convert grouped data to array format if needed
    return Object.values(groupedData);
}

// export const generateTenderData = () => {
//     return restructuredTenderData(tenderData, packages, packageDuration, projectsMetadata)
// }

export function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function quickCompareArrays<T>(arr1: T[], arr2: T[]) {
    if (arr1.length - arr2.length) { return false; }
    var i = 0;
    for (; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) { return false; }
    }
    return true;
}

/*
  ,----Arithmetic DSL parser and evaluator---
  | This is an implemenation of a parser and evaluator of arithmetic notation.
  | It takes a state containing the values of the variables, and an infix string expression, and outputs a number.
  | It supports spaces (ignores), variables (alphabets), numbers, and PEMDAS operators.
  | Internally, it converts infix to prefix (Polish) to make it easier to parse and evaluate.
  | The prefix statement is then evaluated. This allows for easier readability and debugging.
  | The performance hit is also negligible since the infix to prefix operation is done only once.
  `----
*/

type State = Record<string, number>;

type ASTNode =
    | { type: "number"; value: number }
    | { type: "variable"; name: string }
    | { type: "binary"; operator: string; left: ASTNode; right: ASTNode };

const precedence: Record<string, number> = {
    "^": 3,
    "*": 2,
    "/": 2,
    "+": 1,
    "-": 1,
};

const isOperator = (char: string) => "+-*/^".includes(char);

// Reverse an infix expression for prefix conversion
const reverseExpression = (expr: string): string[] =>
    expr
        .split("")
        .reverse()
        .map((char) => (char === "(" ? ")" : char === ")" ? "(" : char))
        .join("")
        .match(/(\d+(\.\d+)?|[a-zA-Z]+|[\+\-\*\/\^\(\)])/g) || [];

// Convert infix to postfix using the Shunting Yard Algorithm
const infixToPostfix = (tokens: string[]): string[] => {
    const output: string[] = [];
    const operators: string[] = [];

    tokens.forEach((token) => {
        if (!isNaN(Number(token))) {
            output.push(token);
        } else if (/^[a-zA-Z]+$/.test(token)) {
            output.push(token.split("").reverse().join(""));
        } else if (isOperator(token)) {
            while (
                operators.length &&
                precedence[operators[operators.length - 1]] >= precedence[token]
            ) {
                output.push(operators.pop()!);
            }
            operators.push(token);
        } else if (token === "(") {
            operators.push(token);
        } else if (token === ")") {
            while (operators.length && operators[operators.length - 1] !== "(") {
                output.push(operators.pop()!);
            }
            operators.pop(); // Remove "("
        }
    });

    while (operators.length) {
        output.push(operators.pop()!);
    }

    return output;
};

// Convert infix to prefix (returns an array of tokens)
const infixToPrefix = (expression: string): string[] => {
    const reversedTokens = reverseExpression(expression);
    const postfixTokens = infixToPostfix(reversedTokens);
    return postfixTokens.reverse();
};

// Parse the prefix expression into an AST
const parsePrefix = (tokens: string[]): ASTNode => {
    const token = tokens.shift();
    if (!token) throw new Error("Invalid expression");

    if (!isNaN(Number(token))) {
        return { type: "number", value: Number(token) };
    }
    if (/^[a-zA-Z]+$/.test(token)) {
        return { type: "variable", name: token };
    }
    if (isOperator(token)) {
        return {
            type: "binary",
            operator: token,
            left: parsePrefix(tokens),
            right: parsePrefix(tokens),
        };
    }

    throw new Error(`Unexpected token: ${token}`);
};

// Curried function to evaluate an AST
const evaluate = (state: State) => (node: ASTNode): number => {
    switch (node.type) {
        case "number":
            return node.value;
        case "variable":
            var variableValue: any = state[node.name];
            variableValue = typeof variableValue === "string" ? variableValue.length ? Number.parseFloat(variableValue) : 0 : variableValue;
            return variableValue ?? 0; // Default to 0 if variable is missing
        case "binary": {
            const left = evaluate(state)(node.left);
            const right = evaluate(state)(node.right);
            switch (node.operator) {
                case "+": return left + right;
                case "-": return left - right;
                case "*": return left * right;
                case "/": return left / right;
                case "^": return Math.pow(left, right);
                default: return left;
            }
        }
    }
};

/*
  const getVariablesFromAst = (nodes: ASTNode[]): string[] =>
  nodes.reduce((acc, node) => node.type === "variable" ? [...acc, node.name] : acc, []);
*/

// Function to evaluate a DSL expression
export const evaluateExpression = (expression: string) => {
    const prefixTokens = infixToPrefix(expression);
    const ast = parsePrefix(prefixTokens);
    return (state: State): number => {
        return evaluate(state)(ast);
    };
};


export const formatPercentage = (achieved: number, target: number, showLargeValue?: boolean): string => {
    if (!isFinite(achieved) || !isFinite(target) || target === 0) {
      return "0.00";
    }
  
    const ratio = (achieved / target) * 100;
  
    if(ratio.toString().includes('e+')) {
        return ratio.toExponential(2)
    }
    // Convert to string to count digits before decimal
    const [intPart] = Math.abs(ratio).toString().split(".");
    if (intPart.length > 10 && !showLargeValue) {
      return "large value..."; // Show ellipsis for UI
    }
  
    // Show standard percentage with 2 decimal places
    return ratio.toFixed(2);
  };